.questions {
  padding: 0 20px;
}
.questionsTitle {
  color: #222 !important;
  margin-top: 70px !important;
}
@media (min-width: 600px) {
  .questions {
    max-width: 760px;
    margin: 0 auto;
  }
}
