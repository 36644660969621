.footer {
  background-color: #ebebeb;
  padding: 20px;
  text-align: center;
  &__logo {
    margin: 0 auto;
    width: 100px;
    height: 70px;
    display: block;
    img {
      width: 100%;
    }
  }
  &__menu {
    margin-top: 10px;
  }
  &__link {
    color: #444ad3;
    display: block;
    font-size: 23px;
    padding: 13px 0;
  }
  &__social {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    gap: 14px;
  }
  &__social-link {
    width: 48px;
  }
}
