// Variables
/* У CSS */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap");

$font-family: "Oswald", Arial, sans-serif;
$primary-color: #444ad3;
$secondary-color: #fafaff;
$tertiary-color: #f22c2c;

// Mixins
@mixin background-image($path) {
  background-image: url("#{$path}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin font-styles {
  font-family: $font-family;
  font-weight: 400;
  // Додайте інші властивості, які повторюються
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: 700;
}
.red {
  color: red;
}
.redBackground {
  background-color: red;
  color: #fff;
}
.orange {
  background-color: orange;
}

// Global styles
* {
  padding: 0;
  margin: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  @include font-styles;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

a:visited,
a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

// App styles
.App {
  overflow: hidden;
}

body {
  font-size: 20px;
  @include font-styles;
}

input[type="checkbox"] {
  display: none;
}

.header {
  top: 0;
  z-index: 345;
  position: fixed;
  background-color: $secondary-color;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px 0px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__logo {
    width: 60px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__menu_li {
    display: block;
  }
  &__menu {
    max-height: 0vh;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -23;
    margin-top: 50px;
    position: absolute;
    transition: opacity 0.6s;
    background-color: #000000a9;
  }
  &__wrapper {
    background-color: $secondary-color;
    padding-bottom: 50px;
    overflow: auto;
    height: 100%;
    transition: all 0.3s;
    top: -500px;
    position: relative;
    z-index: -3;
  }
  &__wrapper--active {
    top: 0;
    z-index: 2;
  }
  &__menu-block {
    text-align: center;
    width: 100%;
    padding: 30px 20px 30px;
  }
  &__menu-social-block {
    display: flex;
  }
  &__menu-social-img {
    display: block;
    width: 24px;

    height: 24px;
  }
  &__mobile {
    display: flex;
  }
  &__mobile-link {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    position: relative;
  }
  &__mobile_number {
    position: absolute;
    min-width: 14px;
    height: 14px;
    color: #fff;
    border-radius: 30px;
    position: absolute;
    right: -3px;
    bottom: -3px;
    text-align: center;
    line-height: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    padding: 0 3px;
    display: block;
    z-index: 2342345;
    font-weight: 600;
  }
  &__mobile_number_red {
    background-color: $tertiary-color;
  }
  &__mobile_number_blue {
    background-color: $primary-color;
  }
  &__mobile-button {
    margin-left: 15px;
    transition: all 0.3s;
    transform: scale(1);
    &:hover {
      transform: scaleY(0.9);
    }
    span {
      display: block;
      width: 100%;
      height: 3px;
      transition: all 0.3s;
      background-color: $primary-color;
      position: relative;

      &:first-child {
        top: 12px;
      }
      &:last-child {
        top: 15px;
      }
    }
  }
  &__menu-block-link {
    margin-bottom: 20px;
    display: block;
    color: #222;
    transition: 0.3s;
    letter-spacing: 0.5px;
    &:hover {
      color: $primary-color;
    }
  }
  &__menu-block-link.active {
    color: $primary-color !important;
  }
  &__menu-description {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  &__menu-social-block {
    padding-top: 20px;
    justify-content: center;
    gap: 10px;
  }
  &__menu--active {
    opacity: 1;
    min-height: 100vh;
    z-index: 2;
    max-height: 100vh;
  }

  &__mobile-button--active {
    span {
      display: none;
      &:first-child {
        display: block;
        transform: rotate(45deg);
      }

      &:last-child {
        top: 9px;
        position: relative;
        display: block;
        transform: rotate(-45deg);
      }
    }
  }
}
.showed {
  max-height: 0px;
  overflow-y: scroll;
  div {
    margin: 7px 0;
    font-size: 15px;
  }
}
.showedActive {
  max-height: 70px;
  padding: 5px 0;
}

.firstSection {
  background: rgb(107, 54, 243);
  background: radial-gradient(
    circle,
    rgba(107, 54, 243, 1) 0%,
    rgba(155, 136, 229, 1) 0%,
    rgba(107, 54, 243, 1) 42%
  );
  padding-top: 55px;
  color: #fff;
  text-align: center;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.9px;
    text-transform: uppercase;
  }
  &__description {
    font-size: 26px;
    margin-bottom: 10px;
  }
  &__button {
    font-weight: 700;
    color: #ffffff;
    background-color: $primary-color;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 6px 0;
    text-transform: uppercase;
    padding: 15px 30px;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.4)
      );
      width: 45px;
      height: 100%;
      top: 0;
      left: 30px;
      z-index: 2;
      animation-name: flash-md;
      transform: translateX(-85px) skewX(-45deg);
      animation-duration: 3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  }
}
@keyframes flash-md {
  0% {
    transform: translateX(-400px) skewX(-45deg);
  }
  100% {
    transform: translateX(400px) skewX(-45deg);
  }
}
.title {
  color: rgb(68, 74, 211);
  font-size: 30px;
  line-height: 110%;
  margin: 22px 0;
  text-align: center;
  font-weight: 600;
}

.slider {
  overflow: hidden;
  &__row {
    padding: 0 20px 5px;
    display: flex;
  }

  &__cart {
    position: relative;
    display: block;
  }
  &__cart-img-block {
    border-radius: 20px !important;
    overflow: hidden !important;
  }
  &__cart-img {
    &:first-child {
      display: block !important;
    }
    &:last-child {
      display: none !important;
    }
    &:hover {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
  &__cart-title {
    color: #000000;
    font-weight: 700;
    text-align: center;
    margin: 10px 0;
    font-size: 20px;
  }
  &__cart-price {
    font-size: 14px;
    justify-content: center;
    display: flex;
  }
  &__cart-price-current {
    color: #ff2e2e;
    margin-right: 7px;
  }
  &__cart-price-old {
    color: #bdbdbd;
    text-decoration: line-through;
  }
  &__cart-button {
    font-size: 16px;
    line-height: 150%;
    color: #fff;
    padding: 14px 0;
    background-color: $primary-color;
    width: 100%;
    border-radius: 16px;
    margin-top: 10px;
  }
  &__cart-button-red {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ffffff;
    background-color: #ff0000;
    font-weight: 400;
    font-size: 12px;
    font-family: "Roboto", Arial, sans-serif;
    width: 65px;
    display: flex;
    align-items: center;
    height: 50px;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    padding: 0 10px;
  }
  &__cart-like {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
    }
  }
}
.swiper-slide {
}
.swiper-wrapper {
  display: flex;
}

.box {
  padding: 0 20px;
  &__title {
    margin: 26px 0;
  }
  &__img-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding-top: 56.25%;
    position: relative;
  }
  &__title-blue {
    margin-top: 10px;
  }
  &__title-red {
    margin-top: 10px;
    color: #ff0000;
    margin-bottom: 40px;
  }
  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}

.drawer--active {
  position: fixed !important;
  z-index: 234222 !important;
  opacity: 1 !important;
}
.drawer {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: -2;
  opacity: 0;
  overflow: scroll;
  p {
    font-size: 15px;
    position: relative;
    top: -17px;
  }
  // overflow: hidden;
  &__error {
    background: #f95d51;
    color: #fff;
    padding: 20px;
    margin-bottom: 10px;

    text-align: center;
    font-size: 16px;
    line-height: 1.45;
  }

  &__wrapper {
    padding: 20px;
  }
  &__title {
    color: #000000;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 1.35;
    position: relative;
    font-weight: 600;
  }
  &__exit {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 20px;
    height: 20px;
    right: 0px;

    font-weight: 600;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      width: 25px;
      height: 2px;
      background-color: #9f9c9c;
      transform: rotate(45deg);
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      width: 25px;
      height: 2px;
      background-color: #9f9c9c;
      transform: rotate(-45deg);
    }
  }
  &__cart-type {
    font-size: 12px !important;
    margin: 5px 0;
    font-weight: 400;
    opacity: 0.7;
    display: block;
  }
  &__label {
    margin-bottom: 10px;
  }
  &__carts-block {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 35px 0;
    &::before {
      content: "";
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      position: absolute;
      background-color: #bdbdbd;
    }
    &::after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      position: absolute;
      background-color: #bdbdbd;
    }
  }
  &__cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__cart-img-block {
    min-width: 70px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 10px;
  }
  &__cart-img {
    width: 100%;
    background-color: #0000;
  }
  &__cart-text {
    flex: 1;
    p {
      top: 0;
    }
  }

  &__cart-title {
    font-weight: 600;
    color: #000;
    font-size: 16px;
    line-height: 1.45;
    margin-bottom: 5px;
  }
  &__cart-minus,
  .drawer__cart-plus {
    width: 16px;
    height: 16px;
    display: inline-block;
    color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    text-justify: center;
  }
  &__cart-plus {
    margin-right: 20px;
  }

  &__cart-minus {
    align-items: self-end;
  }
  &__cart-text-wrapper {
    display: flex;
    margin-top: 5px;
    align-items: center;
  }
  &__cart-counter {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    margin: 0 10px;
    color: #000;
  }
  &__cart-price {
    font-size: 16px;
    color: #000;
  }
  &__button-remove {
    min-width: 20px;
    align-self: start;
    height: 20px;
    display: flex;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #9f9c9c;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%) rotate(45deg);
      width: 13px;
      background-color: #9f9c9c;
      height: 2px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%) rotate(-45deg);
      width: 13px;
      background-color: #9f9c9c;
      height: 2px;
    }
  }

  &__price {
    text-align: right;
    margin: 30px 0;
    font-size: 16px;
    font-weight: 600;
  }
  &__description {
    color: rgb(68, 74, 211);
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
  }
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  &__box {
    position: relative;
    display: block;
    &::before {
      content: "";
      @include background-image("./checkMark.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
      top: -5px;
      position: absolute;
      left: -5px;
      width: 30px;
      height: 30px;
      opacity: 0;
      border-radius: 50%;
      transition: all 0.3s;
      background-color: $primary-color;
      transform: scale(0.5);
    }
  }
  &__box-img {
  }
  &__box-title {
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
  }
  &__box-checkbox {
    position: absolute;
    z-index: -10;
    opacity: 0;
  }
  &__text {
    font-size: 16px;
    padding-bottom: 5px;
    &:last-child {
      margin-top: 20px;
    }
  }
  &__input {
    color: #000000;
    border: 2px solid $primary-color;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 100%;
    padding: 15px 20px;
    font-size: 16px;
    line-height: 1.33;
    display: block;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &__label {
    margin-bottom: 40px;
  }
  &__label-title {
    font-size: 12px;
    margin-bottom: 13px;
    line-height: 1.45;
    font-weight: 400;
    padding-left: 30px;
    display: block;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid $primary-color !important;
      border-radius: 50%;
    }
    &::after {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 1;
      z-index: 222;
      transition: all 0.5s;
    }
  }
  &__button {
    color: #ffffff;
    z-index: 234234123;
    background-color: $primary-color;
    border-radius: 20px;
    text-align: center;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin: 0;
    outline: 0;
    margin-bottom: 30px;
    width: 100%;
  }
  &__smallText {
    font-size: 12px;
    line-height: 1.45;
    text-align: center;
    margin-bottom: 50px;
    a {
      color: rgb(68, 74, 211);
      text-decoration: underline;
    }
  }
  &__label-input {
    position: absolute;
    z-index: -2;
    opacity: 0;
  }
}

.drawer__box-checkbox:checked + .drawer__box::before {
  opacity: 1;
  transform: scale(1);
}
.drawer__label-input:checked + .drawer__label-title:after {
  opacity: 1;
  background-color: rgb(68, 74, 211);
}

.like--active {
  position: fixed !important;
  z-index: 411 !important;
  opacity: 1 !important;
  transform: translateX(0) !important;
  display: block !important;
}
.searchCart {
  margin: 10px 0;
}
.like {
  transition: all 0.5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 000px;
  background-color: #fff;
  z-index: -2;
  width: 100% !important;
  // display: none;
  opacity: 0;
  transform: translateX(700px);

  overflow: scroll;
  &__wrapper {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__title {
    color: #000000;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 1.35;
    position: relative;
    font-weight: 600;
  }
  &__exit {
    position: absolute;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    top: 50%;
    right: 0px;

    font-weight: 600;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      width: 25px;
      height: 2px;
      background-color: #9f9c9c;
      transform: rotate(45deg);
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      width: 25px;
      height: 2px;
      background-color: #9f9c9c;
      transform: rotate(-45deg);
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 35px 0;
    position: relative;
    &::before {
      content: "";
      left: -20px;
      right: -20px;
      top: 0;
      height: 1px;
      position: absolute;
      background-color: #bdbdbd;
    }
  }
  &__nothing {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  &__text {
    font-weight: 600;
    color: #000;
    font-size: 26px;
    line-height: 1.45;
    margin-bottom: 10px;
  }
  &__button {
    font-size: 16px;
    line-height: 150%;
    color: #fff;
    padding: 14px 0;
    background-color: $primary-color;
    width: 60%;
    border-radius: 16px;
    margin-top: 10px;
  }
}
.page {
  &__wrapper {
    padding: 0 20px;
    padding-top: 100px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    margin-bottom: 50px;
  }
  &__container {
    padding: 0 20px;
  }

  &__title {
    margin-bottom: 50px;
    text-align: center;
    font-size: 28px;
    font-family: $font-family;
    font-weight: 600;
    color: #000000;
    line-height: 1.23;
  }
  &__description {
    font-size: 16px;
    line-height: 1.45;
    font-family: $font-family;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
}

.CartPage {
  margin-top: 100px;
  &__title {
    font-size: 20px;
    line-height: 135%;
    font-weight: 600;
    margin-top: 24px;
    padding: 0 20px;
    margin-bottom: 18px;
  }
  &__slider {
    // display: flex;
    min-height: 200px;
    padding: 0 60px 41px !important;
  }
  &__slide {
    min-height: 200px;
    border-radius: 30px;
    overflow: hidden;
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 100px;
    width: 40px;
    align-items: center;
    transform: translateY(-30%);
    &:after {
      font-size: 10px;
      color: #000;
    }
  }
  .swiper-pagination {
    top: auto;
    bottom: 0;
  }

  .swiper-slide-fully-visible,
  .swiper-slide-fully-active,
  .swiper-slide {
    transition: opacity 0.5s !important;
  }
  .swiper-pagination-bullet {
    width: 36px;
    opacity: 1;
    border-radius: 5px;
    overflow: hidden;
    height: 36px;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
      border-color: rgba(0, 0, 0, 0.15);
    }
  }
  .swiper-pagination-bullet-active {
    opacity: 0.8;
  }
  &__row {
    display: flex;
    padding: 0 20px;
    margin-bottom: 9px;
    align-items: center;
  }
  &__price {
    color: #f00;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.6px;
  }
  &__oldPrice {
    text-decoration: line-through;
    color: #bdbdbd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.6px;
    margin-left: 5px;
  }
  &__checkBoxs_block {
    position: relative;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  &__checkBoxs_active {
    display: inline-flex;
    min-height: 28px;
    padding: 3px 11px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #f8f8f8;
    margin-top: 5px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 11px;
      width: 14px;
      height: 15px;
      transform: translateY(-50%);
      background-image: url("arrow-down-checkboxs.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &__checkBoxs_img {
    border: 1px solid #ddd;
    display: block;
    width: auto;
    height: 34px;
    margin-right: 5px;
  }
  &__checkBoxs_row {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &__checkBoxs_row--active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &__checkBoxs_wrapper {
    padding: 5px 0;
    z-index: 10;
    display: inline-block;
    position: absolute;
    top: 25px;
    z-index: -2;
    opacity: 0;
    left: 20px;
    background-color: #fff;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  &__checkBoxs_wrapper--active {
    opacity: 1;
    z-index: 3;
  }
  &__checkBoxs_title {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-right: 35px;
    line-height: 18.6px;
  }
  &__button {
    border-radius: 10px;
    background: #444ad3;
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 29px;
    color: #fff;
  }
  &__like {
    border-radius: 50%;
    display: flex;
    height: 50px;
    padding: 15px 12px;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;
    border: 1px solid #e4e4e4;
    background-color: transparent;

    svg {
      width: 50%;
      transform: scale(1);
      transition: all 0.3s;
      fill: none;
      width: 25px;
      height: 22px;
    }
    path {
      stroke: #000;
    }
    &:hover {
      svg {
        transition: all 0.3s;
        transform: scale(1.1);
      }
    }
  }
  &__like_active {
    svg {
      animation: likeAnimations 0.3s;
      fill: red;
    }
    path {
      stroke: red;
    }
  }

  &__text {
    padding: 0 20px;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 18.6px;
    margin-bottom: 65px;
  }
  &__block_title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    padding: 0 20px;
    line-height: 35px;
    margin-bottom: 100px;
  }
  &__block_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 100px;
    padding: 0 10px;
  }
  &__orderedImg {
    display: block;
    border-radius: 20px;
    width: 100%;
    height: auto;
  }
}

.feedback {
  .swiper-pagination {
    padding-bottom: 0px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      font-size: 5px !important;
    }
  }
}

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  opacity: 0.4 !important;
  background: transparent;
  height: 14px;
  border: 1px solid grey;
  padding: 2px 0;
}

.LikeButton {
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: transparent;
  z-index: 234;

  &:hover {
    opacity: 1 !important;
  }
  &__wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease 0s;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }
  .header__mobile_number_blue {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
  }
}
.AddButton {
  position: fixed;
  bottom: 100px;
  right: 10px;
  background-color: transparent;
  z-index: 1234;
  &__wrapper {
    // z-index: -1234;

    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease 0s;
    background-color: #9e24e0;
    border-radius: 50%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
    transition: all 0.3s;
    img {
      display: block;
      width: 34px;
    }
  }
  .header__mobile_number_red {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
  }
}
.LikeButton_animation {
  animation: likeAnimations 0.5s ease-in-out;
}

@keyframes likeAnimations {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.modal-image {
  width: 100%; /* Ensure the image takes the full width of the modal */
}

.close-button {
  /* Style for the close button */
  cursor: pointer;
}
.ReactModal__Content {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 2345;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  &::before {
  }
}
.TextShow {
  position: absolute;
  -webkit-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  top: 50%;
  right: 130%;
  white-space: nowrap;
  background: #292929;
  color: #fff;
  font-size: 15px;
  box-sizing: border-box;
  padding: 9px 13px;
  border-radius: 3px;
  transition: opacity 0.3s linear;
  opacity: 0;
  pointer-events: none;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    top: 50%;
    right: -20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-left-color: #292929;
  }
}
.TextShow_active {
  opacity: 1;
}

.LikeText {
  background-color: #000;
  color: #ffff;
  padding: 20px;
  position: fixed;
  z-index: 9999999;
  background-color: #111;
  max-width: 350px;
  bottom: 50px;
  right: 20px;
  border-radius: 10px;
  z-index: -23;
  transition: opacity 0.4s, right 0.4s;
  opacity: 0;
}
.LikeText_animation {
  animation: textAnimations 2s ease-in-out;
}

@keyframes textAnimations {
  0% {
    right: -400px;
    opacity: 1;
  }
  70% {
    right: 20px;
    z-index: 22121;
    opacity: 1;
  }
  100% {
    z-index: 22121;
    opacity: 0;
  }
}

.Admin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 23453;
  background-color: #fff;
  padding: 40px 0;
  overflow: auto;
  &__wrapper {
    padding: 0 20px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
  }
  &__button_top {
    margin-bottom: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: transparent;
    border: 1px solid black;
  }
  &__button_top_active {
    border: 1px solid $primary-color;
    color: $primary-color;
  }
  &__input {
    border-radius: 15px;
    width: 100%;
    color: black;
    border: 1px solid black;
    padding: 12px 90px 12px 20px;
    transition: all 0.3s;
    &:focus {
      border: 1px solid $primary-color;
    }
  }
  &__button {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    padding: 6px 13px;
    border-radius: 15px;
    transition: all 0.3s;
    border: 1px solid black;
    color: #000;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    &:hover {
      border: 1px solid $primary-color;
      color: $primary-color;
    }
  }
  &__type {
    display: flex;
    border-radius: 15px;
    border: 1px solid $primary-color;
    padding: 17px 20px;
    margin: 10px 0;
  }
  &__type_text {
    flex: 1;
  }
  &__type_button {
    margin-left: 10px;
    background-color: transparent;
  }
}
.Modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -12345321;
  backdrop-filter: blur(10px);
  transition: all 0.3s;
  opacity: 0;
  display: flex;
  align-items: center;
  &__wrapper {
    max-height: 90%;
    transition: all 0.3s;
    border: 1px solid #e4e4e4;
    padding: 20px 10px;
    border-radius: 10px;
    background-color: #fff;
    width: 90%;
    margin: 0 auto;
    overflow: auto;
    position: relative;
  }
}
.Modal_active {
  z-index: 12345321;
  opacity: 1;
}
.TypeModal {
  &__title {
    margin-bottom: 20px;
    font-weight: 700;
  }
  &__close {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 20px;
    z-index: 234;
    right: 20px;
    background-color: transparent;
    &::before {
      width: 20px;
      height: 2px;
      background: #cac8c8;
      position: absolute;
      top: 10px;
      right: 2px;
      transform: rotate(45deg);
      z-index: 234;
      content: "";
    }
    &::after {
      width: 20px;
      height: 2px;
      background: #cac8c8;
      position: absolute;
      right: 2px;
      top: 10px;
      transform: rotate(-45deg);
      z-index: 234;
      content: "";
    }
  }
}
.TypeActive {
  display: block;
}
.Type {
  display: none;
  &__row {
    display: flex;
    border: 1px solid #e4e4e4;
    padding: 15px;
    border-radius: 10px;
    margin: 20px 0;
    align-items: center;
    max-height: 120px;
  }
  &__img_wrapper {
    overflow: hidden;
    margin-right: 15px;
    padding-top: 23.25%;
    border-radius: 10px;
    width: 95px;
    position: relative;
  }
  &__img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    transform: translate(-50%, -50%);
  }
  &__text_wrapper {
    flex: 1;
  }
  &__title {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 10px;
  }
  &__price {
    color: #ff3a3a;
    font-size: 14px;
    font-weight: 400;
  }
  &__oldPrice {
    color: #bdbdbd;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
  }
  &__wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__button {
    border-radius: 10px;
    background: #444ad3;
    height: 50px;
    padding: 13px;
  }
}

.ModalCard {
  overflow: auto;
  .Modal__wrapper {
    padding: 50px 20px;
  }
  &__slider {
    margin-bottom: 30px;
  }
  &__slide {
    height: auto;
  }
  &__title {
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 5px;
  }
  &__description {
    background-color: #eee;
    width: 100%;
    border: 1px solid $primary-color;
    border-radius: 15px;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  textarea {
    resize: none;
    width: 100%;
    display: block;
    height: 100px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    border-radius: 10px;
    background: #444ad3;
    padding: 10px 25px;
    font-size: 16px;
    color: #fff;
  }
  &__img_block {
    position: relative;
    height: 100%;
    background-color: #e4e4e4;
    border-radius: 10px;
  }
  &__img_button {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    bottom: 5px;
  }
  &__img_button_change {
    background-color: grey;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;
    }
  }
  &__img_block_last {
    button {
      width: 50px;
      top: 35%;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
  &__img_button_delete {
    background-color: red;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 70%;
    }
  }
  &__radio {
    margin: 10px 0;
    label {
      margin: 0 15px 0 10px;
      font-size: 15px;
    }
  }
}
.swiper-slide-active {
  z-index: 1234521;
}
.NothingFound {
  text-align: center;
  font-size: 20px;
  color: #000;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  padding: 30px;
  opacity: 0.7;
}
.Oferta {
  margin: 0 auto;
  margin-top: 150px;
  max-width: 800px;
  padding: 0 20px;
  display: block;
}
.container {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}

.customFirstSection {
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  margin: 20px;
  background-position: center center;
  margin-top: 110px;
  &_wrapper {
    color: #fff;
    text-align: center;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 700px;
      align-items: center;
    }
  }
  &_title {
    font-size: 30px;
    line-height: 1.23;
    font-family: "Oswald", Arial, sans-serif;
    font-weight: 600;
    color: #fff;
  }
  &_description {
    padding: 20px 0 34px;
    font-size: 18px;
    line-height: 1.45;
    font-weight: 400;
  }
  &_button {
    font-weight: 700;
    border-radius: 20px;
  }
}

.secondSection {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #ebebeb;
  &_title {
    color: #000;
    margin-bottom: 45px;
  }
  &_row {
    position: relative;
    counter-reset: myCounter;
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      bottom: 80px;
      left: 23px;
      width: 2px;
      background-color: $primary-color;
    }
  }
  &_block {
    padding-left: 70px;
    padding-bottom: 30px;
    position: relative;
    &::before {
      content: counter(myCounter);
      counter-increment: myCounter;
      width: 46px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 100%;
      font-size: 16px;
      line-height: 1.35;
      border: 2px solid #fff;
      position: absolute;
      font-weight: 600;
      top: 0;
      background-color: $primary-color;
      left: 0;
    }
  }
  &_block_title {
    color: $primary-color;
    line-height: 1.35;
    font-size: 18px;
    font-weight: 600;
  }
  &_block_description {
    font-size: 12px;
    font-weight: 400;
    margin: 5px 0;
    line-height: 1.45;
  }
}
.customForm {
  padding: 105px 0;
  .container {
    display: flex;
    flex-direction: column;
  }

  .drawer__input {
    border: 3px solid #444ad3;
    margin: 0;
    border-radius: 20px;
  }

  &_title {
    margin-bottom: 75px;
    color: #000;
  }
  &_row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  &_column {
    margin-bottom: 25px;
  }
  &_name {
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 1.45;
    font-family: "Oswald", Arial, sans-serif;
    font-weight: 400;
    color: #000000;
  }
  &_order_button {
    width: 100%;
    justify-content: center;
  }
  &_description {
    padding-bottom: 15px;
    font-size: 12px;
    opacity: 0.7;
    line-height: 1.55;
    font-weight: 400;
  }
  &_addedFile {
    display: inline-block;
    margin: 0 5px;
    background-color: $primary-color;
    border-radius: 20px;
    color: #fff;
    font-size: 10px;
    padding: 5px;
  }
  input[type="file"] {
    display: none;
  }
}
.customFooter {
  margin-top: 30px;
  text-align: center;
  font-size: 12px;
  padding: 0 20px;
  line-height: 1.45;
  a {
    color: $primary-color;
  }
}
.customOrdered {
  padding: 0 20px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 150px;

  &_title {
    color: #000;
    grid-column-end: 3;
    display: grid;
    grid-column-start: 1;
  }
  &_cart {
    margin: 0 auto;
    max-width: 380px;
  }
}
.NotLoaded {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  opacity: 0.8;
  &_img {
    width: 100%;
    aspect-ratio: 3 / 3;
    background-color: grey;
    border-radius: 20px;
  }
  &_title {
    margin: 20px 0 12px;
    background-color: grey;
    min-height: 35px;
    border-radius: 10px;
  }
  &_row {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  &_red {
    background-color: red;
    min-height: 15px;
    border-radius: 10px;
    display: inline-block;
    width: 30px;
  }
  &_padding {
    padding-right: 80px;
  }
  &_grey {
    background-color: grey;
    min-height: 15px;
    display: inline-block;
    width: 30px;
    border-radius: 10px;
  }
  &_button {
    background-color: grey;
    border-radius: 16px;
    font-size: 16px;
    line-height: 150%;
    margin-top: 20px;
    padding: 14px 0;
    width: 100%;
    min-height: 54px;
  }
  &_like {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    position: absolute;
    background-color: grey;
    top: 10px;
    right: 10px;
  }
}

@media (min-width: 600px) {
  .page__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .page__container {
    max-width: 560px;
    margin: 0 auto;
  }
  .page__title {
    font-size: 42px;
  }
  .page__description {
    font-size: 20px;
    line-height: 1.55;
  }
  .page__container {
    margin-top: 200px;
  }
  .CartPage__text {
    font-size: 14px;
    line-height: 1.55;
  }
  .CartPage__title {
    font-size: 24px;
    line-height: 1.35;
  }
  .CartPage__oldPrice {
    font-size: 20px;
    line-height: 1.35;
  }
  .CartPage__price {
    font-size: 17px;
    line-height: 1.35;
  }

  .modal-image {
    width: auto;
  }
}
@media (min-width: 800px) {
  .NotLoaded {
    &_padding {
      padding-right: 0px;
    }
  }
}
@media (min-width: 700px) {
  .drawer__wrapper {
    z-index: 131234123;
    position: relative;
    padding: 40px;
    width: 50%;
    height: 90%;
    background: #fff;
    overflow: scroll;
  }
  .like__wrapper {
    width: 46%;
    padding: 40px;
    background-color: #fff;
  }

  .like {
    background-color: transparent;
    display: flex !important;
    justify-content: flex-end;
  }
  .drawer {
    display: flex !important;
    overflow: hidden !important;
    justify-content: center;
    position: fixed !important;
    align-items: center;
  }
  .drawer--active {
    z-index: 1312341 !important;
    opacity: 1 !important;

    background-color: #000000b0 !important;
  }
  .CartPage__block_wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .LikeButton {
    top: 200px;
    height: 100px;
    right: 50px;
    position: fixed;
  }
  .header__mobile_number_red {
    background-color: #f22c2c;
    bottom: -29px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .AddButton__wrapper img {
    width: 55px;
  }
  .LikeButton .header__mobile_number_blue {
    width: 24px;
    height: 24px;
    line-height: 24px;
    bottom: 16px;
    font-size: 14px;
  }
  .AddButton {
    top: 110px;
    right: 50px;
    height: 70px;
    .AddButton__wrapper {
      width: 100px;
      height: 100px;
    }
  }
  .firstSection__button {
    height: 60px;
    font-size: 16px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 6px 0;
    font-weight: 700;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .header {
    .container {
      display: flex;
    }
  }

  .header__menu {
    max-height: 1;
    z-index: 3;
    margin-top: 0px;
    transition: opacity 0.6s;
    background-color: rgba(0, 0, 0, 0.662745098);
    opacity: 1;
    width: 100%;
    position: relative;
    max-height: 100px;
  }

  .header__wrapper {
    background-color: #fafaff;
    padding-bottom: 0px;
    overflow: inherit;
    height: 100%;
    transition: all 0.3s;
    top: 0px;
    position: relative;
    z-index: 1;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }
  .header__menu-block {
    display: flex;
    padding: 0;
    justify-content: space-around;
  }
  .header__menu-block-link {
    margin-bottom: 0px;
    display: block;
    color: #222;
    transition: 0.3s;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 2.4vw;
  }
  .header__menu-description {
    display: none;
  }
  .header__menu-social-block {
    padding-top: 0px;
    justify-content: center;
    gap: 5px;
  }
  .header__menu-social-img {
    display: block;
    width: 20px;
    height: 20px;
  }
  .header__mobile {
    display: none;
  }

  .firstSection {
    height: 350px;
    padding-top: 89px;
    justify-content: flex-start;
  }
  .title {
    font-size: 60px;
    margin-bottom: 10px;
  }
  .box__title-blue,
  .box__title-red {
    font-size: 40px;
    grid-row-start: 4;
  }
  .box {
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    gap: 0 30px;
    grid-template-columns: repeat(2, 1fr);
  }
  .box__title {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .customForm {
    .drawer__box-title {
      font-size: 15px;
    }
  }
  .customForm_order_button,
  .customForm_column_button {
    width: 210px;
    align-self: center;
    text-align: center;
    display: block;
    font-size: 16px;
    text-transform: none;
    padding: 13px 0px !important;
    height: auto;
    border-radius: 20px;
  }
  .customForm_column_button {
    width: 150px;
  }
  .customForm_title,
  .secondSection_title,
  .customOrdered_title,
  .questions_questionsTitle__0MVKc {
    margin-bottom: 75px;
    font-size: 40px;
    line-height: 1.23;
  }
  .secondSection_title {
    margin-bottom: 105px;
  }
  .customOrdered {
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 20px;
    margin-bottom: 100px;
  }
  .customOrdered_title {
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
  }
  .secondSection_row {
    display: flex;
    flex-direction: column;
  }
  .secondSection_block {
    width: 50%;
    padding-left: 50px;
    padding-right: 0px;

    align-self: end;
    &::before {
      left: -24px;
      right: auto;
    }
  }
  .secondSection_block:nth-child(odd) {
    padding-left: 0px;
    padding-right: 50px;
    align-self: start;
    text-align: end;
    &::before {
      left: auto;
      right: -24px;
    }
  }
  .secondSection_row::before {
    left: 50%;
  }
}
.popular {
  &__slider {
    aspect-ratio: 1 / 1.2;
  }
}
@media (min-width: 1024px) {
  .CartPage__wrapper {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .CartPage__slider {
    max-width: 700px;
  }
  .header__menu-block-link {
    font-size: 30px !important;
  }
  .page__wrapper {
    max-width: 1200px;
    padding-top: 150px;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
  .customFirstSection_title {
    font-size: 48px;
  }
  .customFirstSection_description {
    font-size: 22px;
  }
  .secondSection_block_title {
    font-size: 22px;
  }
  .secondSection_block_description {
    font-size: 15px;
  }
  .customForm_name {
    font-size: 20px;
  }
  .customForm_description {
    font-size: 14px;
    line-height: 1.55;
  }
}

.ModalMessage {
  display: flex !important;
  overflow: hidden !important;
  justify-content: center;
  position: fixed !important;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1312341 !important;
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.6901960784) !important;
  &_wrapper {
    z-index: 131234123;
    position: relative;
    padding: 60px;
    border-radius: 30px;
    width: 50%;
    height: 90%;
    text-align: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  &_title {
    font-size: 42px;
    font-family: "Oswald", Arial, sans-serif;
    font-weight: 600;
    color: green;
    line-height: 1.23;
  }
  &_description {
    color: red;
  }
  &_text {
    line-height: 130%;
    a:first-child {
      color: $primary-color;
    }
    a:last-child {
      color: purple;
    }
  }
  &_button {
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 20px;
  }
}
@media (max-width: 700px) {
  .ModalMessage_wrapper {
    width: 90%;
    padding: 30px;
  }
  .ModalMessage_description {
    color: red;
    line-height: 1.2;
  }
}
.modal-image {
  border-radius: 40px;
}
