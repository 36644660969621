.authorization {
  position: fixed;
  left: 0;
  right: 0;
  top: -20px;
  bottom: -20px;
  z-index: 22223;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
.authorization__title {
  color: #000000;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.35;
  position: relative;
  font-weight: 600;
}
.authorization__input {
  color: #000000;
  border: 2px solid #444ad3;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 1.33;
  display: block;
}
.authorization__text {
  font-size: 16px;
  padding-bottom: 5px;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
}
.authorization__button {
  color: #ffffff;
  background-color: #444ad3;
  border-radius: 20px;
  text-align: center;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 0;
  outline: 0;
  margin-bottom: 30px;
  width: 100%;
}
.autorization__span {
  text-align: end;
  flex: 1;
  display: block;
  background-color: transparent;
  color: #444ad3;
}
