.slider {
  overflow: hidden;
  &__cartImgFirst {
    display: block;
  }
  &__cartImgSecond {
    display: none;
  }
  &__row {
    padding: 0 20px;
    display: flex;
  }
  &__cart {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
  }
  &__cartImgBlock {
    border-radius: 20px !important;
    overflow: hidden !important;
    position: relative;
    aspect-ratio: 3 / 3;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0.2;
      background-color: transparent;
      transition: all 0.3s linear;
    }
  }

  &__cartTitle {
    color: #000000;
    font-weight: 700;
    text-align: center;
    margin: 20px 0 12px;
    font-size: 16px;
  }
  &__cartPrice {
    font-size: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  &__cartPriceCurrent {
    color: #ff2e2e;
    margin-right: 7px;
  }
  &__cartPriceOld {
    font-size: 16px;

    color: #bdbdbd;
    text-decoration: line-through;
  }
  &__cartButton {
    font-size: 16px;
    line-height: 150%;
    color: #fff;
    padding: 14px 0;
    background-color: #444ad3;
    width: 100%;
    border-radius: 16px;
    margin-top: 20px;
  }
  &__cartButtonRed {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ffffff;
    background-color: #ff0000;
    font-weight: 400;
    font-size: 12px;
    font-family: "Roboto", Arial, sans-serif;
    width: 65px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 50px;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    padding: 0 10px;
  }
  &__cartLike {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 50%;
      transform: scale(1);
      transition: all 0.3s;
      fill: none;
    }
    path {
      stroke: #000;
    }
    &:hover {
      svg {
        transition: all 0.3s;
        transform: scale(1.1);
      }
    }
  }
  &__cartLike_active {
    svg {
      animation: likeAnimations 0.3s;
      fill: red;
    }
    path {
      stroke: red;
    }
  }
}
@media (min-width: 700px) {
  .slider {
    &__cartTitle {
      font-size: 20px;
    }
  }
}

@keyframes likeAnimations {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
