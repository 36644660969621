$primary-color: #444ad3;

.questions {
  &__block {
    padding: 20px;
    position: relative;
    &::before {
      content: "";
      left: 0;
      right: 0;
      height: 1px;
      position: absolute;
      top: 0;
      background-color: #bdbdbd;
    }
    &:last-child {
      &::after {
        content: "";
        left: 0;
        right: 0;
        height: 1px;
        position: absolute;
        bottom: 0;
        background-color: #bdbdbd;
      }
    }
  }
  &__text {
    color: #222;
    padding-right: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    &::before {
      transition: all 0.3s;
      content: "";
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%) rotate(90deg);
      width: 25px;
      height: 3px;
      background-color: #444ad3;
    }
    &::after {
      content: "";
      transition: all 0.3s;
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%) rotate(0deg);
      width: 25px;
      height: 3px;
      background-color: #444ad3;
    }
  }
  &__text_active {
    &::before {
      transform: translateY(-50%) rotate(45deg);
    }
    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
  &__description {
    line-height: 1.45;
    font-size: 12px;
    color: #222;
    transition: all 0.3s;
    padding: 0;
    overflow: hidden;
    max-height: 0px;
    a {
      color: $primary-color;
    }
    div {
      margin: 8px 0;
      padding-left: 20px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: #000;
      }
    }
    span {
      font-weight: 700;
    }
  }
  &__description_active {
    max-height: 1000px;
    padding: 20px 0;
  }
}

.li {
  padding-left: 20px !important;
  margin: 5px 0 !important;
}

@media (min-width: 600px) {
  .questions__block {
    max-width: 760px;
    margin: 0 auto;
  }
  .questions__text {
    font-size: 24px;
  }
  .questions__description {
    font-size: 16px;
  }
}
